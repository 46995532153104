"use client";

import { Loader } from "@cleanlist-ai/portal/components";
import { useAuth, useUser } from "@clerk/nextjs";

import { useRouter } from "next/navigation";
import { useEffect } from "react";

const Index = () => {
  const router = useRouter();
  const { isSignedIn: isAuthenticated } = useAuth();
  const { user } = useUser();

  useEffect(() => {
    if (isAuthenticated) {
      console.log("Recording webtoken for API requests.");
      router.replace(
        user?.unsafeMetadata.completedOnboarding ? "/app" : "/auth/onboarding"
      );
    } else {
      router.replace("/auth/login");
    }
  }, [isAuthenticated, user]);

  return <Loader />;
};

export default Index;
